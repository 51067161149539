import React from 'react'
import { Link, graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import './blog.css'
import ScheduleSection from '../components/ScheduleSection'

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allSanityPost.edges
    const { currentPage, numPages } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage =
      currentPage - 1 === 1 ? '/blog/' : '/blog/' + (currentPage - 1).toString()
    const nextPage = '/blog/' + (currentPage + 1).toString()

    console.log(this.props)

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="All posts" keywords={[`blog`, `dear donna`]} />
        <div className="blog-page page-heading text-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9">
                <h2 class="text-center text-white m-0">Very Personal</h2>
                <p className="text-white text-center mb-0 mt-2">
                  Because each of my interviews with future clients is
                  essentially a teachable moment, keeping a diary allows my
                  clients and others a unique perspective into the constantly
                  evolving world of dating and relationships. By reading about
                  what others set as their expectations and desires for their
                  future relationships each of us is afforded the opportunity to
                  personally reflect on what it is we want for ourselves. Enjoy!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="page-content">
          <Container>
            <Row className="justify-content-center">
              <Col sm={9}>
                <div className="blog-lists">
                  {posts.map(({ node }) => {
                    const title = node.title || node.slug.current

                    return (
                      <div className="blog-lists-item" key={node.id}>
                        <LazyLoadComponent>
                          <h4 className="mt-0">
                            <Link
                              className="text-dark"
                              style={{ boxShadow: `none` }}
                              to={`/blog/${node.slug.current}`}
                              title={title}
                            >
                              {title}
                            </Link>
                          </h4>
                          <div className="category-list-author d-none">
                            <ul className="list-unstyled d-flex justify-content-start">
                              <li className="mr-2">
                                <span className="text-primary">By</span>:{' '}
                                {node.author.name}
                              </li>
                              <li>
                                <span className="text-primary">Published</span>:{' '}
                                {node.publishedAt}
                              </li>
                            </ul>
                          </div>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: node.shortDescription,
                            }}
                          />
                          <div className="d-flex justify-content-start">
                            <Link
                              className="pl-0 alink-transparent text-danger"
                              to={`/blog/${node.slug.current}`}
                            >
                              Read More
                            </Link>
                          </div>
                        </LazyLoadComponent>
                      </div>
                    )
                  })}
                </div>
              </Col>
            </Row>

            <div className="d-flex justify-content-center align-items-center">
              {!isFirst && (
                <Link
                  className="prevPage hvr-shadow d-none d-md-inline"
                  to={prevPage}
                  rel="prev"
                >
                  <img className="mb-0" src="/prev-icon.png" alt="next" />
                </Link>
              )}
              <ul className="list-unstyled d-flex mb-0 pagination-number text-truncate">
                {Array.from({ length: numPages }, (_, i) => (
                  <li>
                    <Link
                      activeClassName="current"
                      key={`pagination-number${i + 1}`}
                      to={`blog/${i === 0 ? '' : i + 1}`}
                    >
                      {i + 1}
                    </Link>
                  </li>
                ))}
              </ul>
              {!isLast && (
                <Link
                  className="nextPage hvr-shadow d-none d-md-inline"
                  to={nextPage}
                  rel="next"
                >
                  <img className="mb-0" src="/next-icon.png" alt="next" />
                </Link>
              )}
            </div>
          </Container>
        </div>
        <ScheduleSection />
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query pageQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          title
          shortDescription
          slug {
            current
          }
          publishedAt
          _createdAt
          _updatedAt
          author {
            id
            name
            image {
              asset {
                id
                url
                fluid {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`
